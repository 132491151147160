import React from 'react'

const LearnIcon = ({ width = '20', height = '20', fill = '#3B4540' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1480_9064)">
        <path opacity="0.2" d="M20.166 7C19.1051 7 18.0877 7.42143 17.3376 8.17157C16.5874 8.92172 16.166 9.93913 16.166 11C16.166 9.93913 15.7446 8.92172 14.9944 8.17157C14.2443 7.42143 13.2269 7 12.166 7H3.16602V25H12.166C13.2269 25 14.2443 25.4214 14.9944 26.1716C15.7446 26.9217 16.166 27.9391 16.166 29C16.166 27.9391 16.5874 26.9217 17.3376 26.1716C18.0877 25.4214 19.1051 25 20.166 25H29.166V7H20.166Z" fill={fill}/>
        <path d="M16.166 11C16.166 9.93913 16.5874 8.92172 17.3376 8.17157C18.0877 7.42143 19.1051 7 20.166 7H29.166V25H20.166C19.1051 25 18.0877 25.4214 17.3376 26.1716C16.5874 26.9217 16.166 27.9391 16.166 29" stroke="#3B4540" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.16602 25H12.166C13.2269 25 14.2443 25.4214 14.9944 26.1716C15.7446 26.9217 16.166 27.9391 16.166 29V11C16.166 9.93913 15.7446 8.92172 14.9944 8.17157C14.2443 7.42143 13.2269 7 12.166 7H3.16602V25Z" stroke="#3B4540" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1480_9064">
          <rect width="32" height="32" fill="white" transform="translate(0.166016)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LearnIcon;
