import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const LocationInput = dynamic(() => import('./LocationInput'), {
  ssr: false,
  loading: () => <div className='shimmer w-32 h-10 rounded-2xl my-2 mx-4' />
});

const NavLogoLocation = ({ classNames='', hideLocation = false, id = 'home-location', isHomePage = false }) => {

  const router = useRouter();

  const handleClick = () => {
    router.push("/");
  }

  useEffect(() => {
    if (document) {
      const navLocation = document.getElementById("home-location");
      if (navLocation && hideLocation) {
        navLocation.style.display = 'none';
      }
    }
  }, []);

  return (
    <div className={`flex items-center ${classNames}`}>
      <div className='cursor-pointer w-[98px]' onClick={handleClick}>
        <img src="https://playo-website.gumlet.io/playo-website-v2/logos-icons/new-logo-playo.png?q=50" alt="Playo" width={96} height={24} className="py-2 min-w-[98px]" loading='lazy' />
      </div>
      <div className='ml-4 cursor-pointer' id={id}>
        <LocationInput homePage={isHomePage} />
      </div>
    </div>
  )
}

export default NavLogoLocation;
