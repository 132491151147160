import React from 'react'

const AppDownload = () => {
  return (
    <div className='p-6 flex items-center backdrop-blur backdrop-brightness-50 rounded-2xl justify-end w-max ml-auto'>
      <img src='https://playo-website.gumlet.io/playo-website-v3/app_qr_code.svg' width={52} height={52} alt='Download Playo' />
      <span className='leading-6 font-bold ml-4 text-white text-center'>DOWNLOAD<br /> THE APP</span>
    </div>
  )
}

export default AppDownload;
