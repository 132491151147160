import React from 'react'
import data from '../../Sitemaps/citiesLinks'
import Accordion from '../../Common/Accordion'

const PLAY_STORE = "https://playo-website.gumlet.io/playo-website-v3/google_play_badge.png"
const APPLE_STORE = "https://playo-website.gumlet.io/playo-website-v3/apple_store_badge.png"

const APPLE_STORE_LINK = "https://itunes.apple.com/us/app/playo-local-sports-community/id1018786950?ls=1&mt=8&utm_source=playo-website&utm_medium=desktop-website&utm_campaign=app-download";
const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.techmash.playo&utm_source=playo-website&utm_medium=desktop&utm_campaign=get-the-app";

const FooterSiteMap = ({ page = 'home' }) => {

  const openAppleStore = () => {
    window.open(
      APPLE_STORE_LINK,
      "_blank"
    );
  }
  
  const openPlayStore = () => {
    window.open(
      PLAY_STORE_LINK,
      "_blank"
    );
  }

  const getSiteMapHeaderData = () => {
    switch (page) {

      case 'activity':
        return 'To create a game, download Playo app';

      case 'home':    
      default:
        return 'Get the Playo app for a seamless experience!';
    }
  }

  return (
    <section className='rounded-2xl overflow-hidden md:rounded-3xl'>
      <div className="bg-primary flex items-center justify-center md:justify-between flex-wrap">
        <h3 className='text-white font-bold text-center md:text-left md:pl-12 text-3xl md:w-1/3 py-6'>{getSiteMapHeaderData()}</h3>
        <div className="flex items-center md:pr-32 md:bg-[url('https://playo-website.gumlet.io/playo-website-v3/footer_spiral.png')] bg-contain bg-no-repeat bg-center py-6">
          <img src={PLAY_STORE} width={'100%'} height={'100'} alt='PlayO' onClick={openPlayStore} className='cursor-pointer' />
          <img src={APPLE_STORE} width={'100%'} height={'100'} alt='PlayO' onClick={openAppleStore} className='cursor-pointer' />
        </div>
      </div>
      <div className='bg-white py-14 md:px-14 px-4'>
        <h3 className='font-bold mb-6 text-2xl'>Top Sports Complexes in Cities</h3>
        <div className='flex flex-wrap gap-6'>
          {data.map((sitemap, index) => (
            <div key={index} className='border px-6 py-4 rounded-2xl grow basis-[48%] shrink h-max'>
              <Accordion title={sitemap.cityName} image={sitemap.image} index={index}>
                <span className='content hidden'>
                  {sitemap.cityData.map((cData, idx) => (
                    <span key={idx}>
                      <a href={cData.url} className='underline'>{cData.title}</a><span className={`${(sitemap.cityData.length === idx + 1) ? 'hidden' : ''}`}>{" | "}</span>
                    </span>
                  ))}
                </span>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FooterSiteMap;
