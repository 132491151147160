import React from 'react'
import NavLogoLocation from './NavLogoLocation';
import { getLocationFromLocalStorage } from '../../util/helperFunctions';

const MobileNav = ({ isHomePage = false }) => {
  return (
    <header className='bg-white md:hidden sticky top-0 z-10'>
      <nav>
        <NavLogoLocation classNames='justify-between py-2 px-4' id='mobile-nav' isHomePage={isHomePage} />
      </nav>
    </header>
  )
}

export default MobileNav;
