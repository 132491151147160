import React from 'react'

const BookIcon = ({ width = '20', height = '20', fill = '#3B4540' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M4.69687 4.69453C5.39123 3.99919 6.21579 3.44748 7.12344 3.07093C8.0311 2.69438 9.00406 2.50038 9.98672 2.5C9.98279 4.48382 9.19255 6.38517 7.78919 7.78736C6.38583 9.18955 4.48382 9.9782 2.5 9.98047C2.50077 8.99824 2.6952 8.02579 3.07217 7.11878C3.44913 6.21176 4.00123 5.38797 4.69687 4.69453ZM12.2094 12.2016C11.5136 12.8947 10.9615 13.7184 10.5846 14.6253C10.2078 15.5322 10.0136 16.5046 10.0133 17.4867C11.9967 17.4832 13.898 16.6942 15.3011 15.2922C16.7042 13.8903 17.4948 11.9897 17.5 10.0063C16.5172 10.0068 15.5441 10.201 14.6363 10.5777C13.7286 10.9544 12.9039 11.5062 12.2094 12.2016Z" fill={fill} />
      <path d="M15.7491 4.25469C14.6128 3.11841 13.165 2.3446 11.5889 2.03111C10.0129 1.71762 8.3792 1.87853 6.89456 2.4935C5.40992 3.10847 4.14098 4.14987 3.2482 5.48602C2.35542 6.82216 1.87891 8.39304 1.87891 10C1.87891 11.607 2.35542 13.1778 3.2482 14.514C4.14098 15.8501 5.40992 16.8915 6.89456 17.5065C8.3792 18.1215 10.0129 18.2824 11.5889 17.9689C13.165 17.6554 14.6128 16.8816 15.7491 15.7453C16.5092 14.994 17.1127 14.0993 17.5246 13.1131C17.9364 12.1269 18.1485 11.0688 18.1485 10C18.1485 8.93124 17.9364 7.8731 17.5246 6.88689C17.1127 5.90068 16.5092 5.006 15.7491 4.25469ZM5.13814 5.13828C6.26312 4.01178 7.74553 3.31257 9.33033 3.16094C9.17758 4.74567 8.47531 6.22705 7.34517 7.34844C6.22227 8.47658 4.74059 9.17708 3.15611 9.32891C3.30904 7.74396 4.00997 6.26198 5.13814 5.13828ZM3.1522 10.5844C5.07133 10.4311 6.87243 9.59681 8.23033 8.23204C9.59597 6.87457 10.4309 5.07333 10.5842 3.15391C12.2 3.28861 13.7157 3.99142 14.8625 5.1376C16.0092 6.28377 16.7128 7.79918 16.8483 9.41485C14.9299 9.57194 13.1295 10.405 11.768 11.7657C10.4065 13.1263 9.57223 14.9262 9.41392 16.8445C7.79819 16.7098 6.28243 16.007 5.13568 14.8608C3.98893 13.7147 3.28537 12.1993 3.14986 10.5836L3.1522 10.5844ZM14.8647 14.8617C13.7395 15.9879 12.2572 16.6871 10.6725 16.8391C10.8286 15.2556 11.5291 13.7752 12.6545 12.6505C13.78 11.5257 15.2608 10.8262 16.8444 10.6711C16.6921 12.2557 15.992 13.7377 14.8647 14.8617Z" fill={fill}/>
    </svg>
  )
}

export default BookIcon;