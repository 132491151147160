import Link from 'next/link';
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router';

import { getLocationFromLocalStorage } from '../../util/helperFunctions';

import HomeIcon from '../Icons/HomeIcon';
import PlayIcon from '../Icons/PlayIcon';
import CoachIcon from '../Icons/CoachIcon';
import BookIcon from '../Icons/BookIcon';
import LoginIcon from '../Icons/LoginIcon';
import dynamic from 'next/dynamic';
import LearnIcon from '../Icons/LearnIcon';
import { UseStateValue } from '../../state';
import { isEmpty } from 'lodash';

const ProfileMobile = dynamic(() => import("../Profile/ProfileMobile"), {
  ssr: false,
  loading: () => <div className="h-6 w-6 rounded-md bg-gray-200"></div>
});

const BottomNav = () => {
  const router = useRouter();
  const [linkToCity, setLinkToCity] = useState();

  const [{ location }, _] = UseStateValue();

  useEffect(() => {
    const storedLocation = getLocationFromLocalStorage();
    if (!isEmpty(storedLocation) || location?.locationLatLon?.length) {
      const { city } = storedLocation || { city: null };
      const { location: newCity } = location?.location || 'bangalore';
      setLinkToCity(city || newCity);
    }
  }, [location]);

  const NAVITEMS = [
    {
      id: 1,
      title: 'Home',
      Icon: HomeIcon,
      URL: '/',
      route: '/',
      fill: '#3B4540'
    },
    {
      id: 2,
      title: 'Play',
      Icon: PlayIcon,
      URL: `/activities?location=${linkToCity || 'bangalore'}&sport=badminton`,
      route: '/activities',
      fill: '#3B4540'
    },
    {
      id: 3,
      title: 'Book',
      Icon: BookIcon,
      URL: linkToCity ? `/venues/${linkToCity}` : "/venues",
      route: '/venues',
      fill: '#3B4540'
    },
    {
      id: 4,
      title: 'Learn',
      Icon: LearnIcon,
      URL: 'https://blog.playo.co',
      route: 'https://blog.playo.co',
      fill: '#3B4540'
    },
    {
      id: 5,
      title: 'Login',
      Icon: LoginIcon,
      loginAttr: true,
      URL: '/',
      route: '/',
      fill: '#3B4540'
    }
  ];

  const selectedItemFill = "#00B562";

  return (
    <div className='sticky md:hidden bottom-0 flex items-center bg-white w-full pt-2 z-10'>
      {NAVITEMS.map(nav => {
        let { Icon, URL = null, id, title, fill, route } = nav;
        let selectRoute = false;
        if (route === router.route) {
          fill = selectedItemFill;
          selectRoute = true;
        }
        if (title === "Login") {
          return (
            <div key={id} className='w-1/5 grow text-center font-medium border-b-[3px] mx-2 border-white'>
              <ProfileMobile />
            </div>
          )
        }
        return (
          <Link href={URL} key={id}>
            <div className={`w-1/5 grow text-center font-medium border-b-[3px] mx-2 ${selectRoute ? 'border-[#00B562]' : 'border-white'}`}>
              <div className='w-6 h-6 m-auto'><Icon height={'1.5rem'} width={'1.5rem'} fill={fill} /></div>
              <div>{title}</div>
            </div>
          </Link>
        )}
      )}
    </div>
  )
}

export default BottomNav;
