import React from 'react'

const Accordion = ({ children, title, index, image }) => {
  const id = `Accordion-${index}`;
  const [isChecked, setIsChecked] = React.useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div>
      <input type='checkbox' id={id} className='hidden' onClick={handleCheckboxChange} />
      <label className='flex items-center gap-1 capitalize font-medium cursor-pointer w-full' htmlFor={id}>
        {image ? <img src={image} width={16} height={16} alt={title} /> : null}
        {title}
        <img src='https://playo-website.gumlet.io/playo-website-v3/icons/down_arrow.png' width={16} height={16} alt={`${title} open`} className={`ml-auto origin-center transition-transform ${isChecked ? '-rotate-90' : ''}`} />
      </label>
      {children}
    </div>
  )
}

export default Accordion;
