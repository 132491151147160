import React from 'react'

const HomeIcon = ({ width = '20', height = '20', fill = '#3B4540' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M16.875 9.02686V16.2503C16.875 16.4161 16.8092 16.575 16.6919 16.6922C16.5747 16.8094 16.4158 16.8753 16.25 16.8753H3.75C3.58424 16.8753 3.42527 16.8094 3.30806 16.6922C3.19085 16.575 3.125 16.4161 3.125 16.2503V9.02686C3.12501 8.93976 3.14322 8.85362 3.17847 8.77398C3.21372 8.69433 3.26523 8.62293 3.32969 8.56436L9.57969 2.66279C9.69477 2.55805 9.84478 2.5 10.0004 2.5C10.156 2.5 10.306 2.55805 10.4211 2.66279L16.6711 8.56436C16.7354 8.623 16.7868 8.69443 16.8219 8.77407C16.857 8.85372 16.8751 8.93982 16.875 9.02686Z" fill={fill} />
      <path d="M17.0961 8.10668L10.8461 2.2098C10.843 2.20714 10.8402 2.20427 10.8375 2.20121C10.6074 1.99194 10.3075 1.87598 9.99648 1.87598C9.68545 1.87598 9.38558 1.99194 9.15547 2.20121L9.14688 2.2098L2.90391 8.10668C2.77656 8.22378 2.67491 8.36604 2.60538 8.52445C2.53586 8.68287 2.49997 8.85399 2.5 9.02699V16.2496C2.5 16.5812 2.6317 16.8991 2.86612 17.1335C3.10054 17.368 3.41848 17.4996 3.75 17.4996H16.25C16.5815 17.4996 16.8995 17.368 17.1339 17.1335C17.3683 16.8991 17.5 16.5812 17.5 16.2496V9.02699C17.5 8.85399 17.4641 8.68287 17.3946 8.52445C17.3251 8.36604 17.2234 8.22378 17.0961 8.10668ZM16.25 16.2496H3.75V9.02699L3.75859 9.01918L10 3.12465L16.2422 9.01762L16.2508 9.02543L16.25 16.2496Z" fill={fill} />
    </svg>
  )
}

export default HomeIcon;
