import DownloadAppMobile from "../components/Banner/DownloadAppMobile";
import AppDownload from "../components/Common/AppDownload";
import BottomNav from "../components/Common/BottomNav";
import HomeNavBar from "../components/Common/HomeNavBar";
import MobileNav from "../components/Common/MobileNav";
import Footer from "../components/StaticPages/Footer/Footer";
import FooterSiteMap from "../components/StaticPages/Footer/FooterSiteMap";

const Layout = ({ children, isHomePage = false, hideLocation = false, page = 'home' }) => {
  return (
    <div className={`bg-surface ${isHomePage ? 'md:pb-40' : ''} text-main box-border`}>

      {/* Download banner for mobile */}
      {isHomePage ? <DownloadAppMobile /> : null}

      {/* Desktop navigation bar */}
      <HomeNavBar hideLocation={hideLocation} />

      {/* Navigation bar in mobile view */}
      <MobileNav isHomePage={isHomePage} />

      {/* Child component of main page */}
      <main className="flex flex-col min-h-[1000px]">{children}</main>

      {/* Seo sitemap routes in footer */}
      <section className="mt-14 md:mx-12 px-4 md:px-0 xxl:mx-auto max-w-page">
        <FooterSiteMap page={page} />
      </section>

      {/* Playo internal navigation routes */}
      <section className="mt-24 md:mx-12 px-4 md:px-0 xxl:mx-auto max-w-page">
        <Footer />
      </section>

      {/* Bottom Navigation bar for mobile view */}
      <BottomNav />

      {/* App Download banner */}
      {isHomePage ? <section className="xxl:mx-auto md:mx-12 max-w-page hidden md:block ml-auto sticky bottom-4 float-right">
        <AppDownload />
      </section> : null}
    </div>
  );
};

export default Layout;
